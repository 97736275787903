import { Component, OnInit, ViewChild, TemplateRef, NgZone } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WebService } from './../web.service';
import { AppContext } from 'src/infrastructure/app.context';
import { FileInfo } from 'src/models/fileInfo';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('content') public content: TemplateRef<any>;

  public imgsBanner = [];

  public loading = true;

  selectedFile: ImageSnippet;

  images = [1, 2, 3].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);

  public closeResult: string;

  fileToUpload: File = null;

  public login = {
    user: "",
    passw: "",
    estado: false
  }

  public categorias = [];
  public productos = [];
  public pedidos = [];
  public detallePedidos = [];
  public pedidoEspecifico = [];

  public informacion = {};

  public administracion: any;

  public categoria = 0;

  public elemento = <HTMLSelectElement>document.getElementById('content');

  // public configuracion = {
  //   id: "",
  //   imgLogo: "",
  //   colorPrincipal: "",
  //   tituloPromocion: "",
  //   tipoPrecio: "",
  //   urlPromocion: "",
  //   urlBanner: "",
  //   urlLogo: "",
  // };

  public configuracion: any;

  public promociones = {
    id: "",
    nombrePromocion: "",
    nombreImagen: "",
    imgPromocion: ""
  }

  public nomPromocion = "";

  public tipoPrecio;

  public cuentaBancaria = {
    numeroCuenta: "",
    nombreBanco: ""
  }

  public costosDetallePedido = {
    totalPedido: 0.0,
    totalPagar: 0.0,
    costoEnvio: 0,
    porcentajeCompraTarjeta: 0,
    porcentajeCodigoDescuento: 0,
    descuentoCodigo: 0.0,
    recargoTarjeta: 0.0
  }

  public filtroFecha = {
    desde: '',
    hasta: ''
  }

  public meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");

  public tituloImagen = '';


  constructor(
    private modalCtrl: NgbModal,
    private ws: WebService,
    private appContext: AppContext,
    private zone: NgZone,
    private localStorage: LocalStorage,
    private toaster: ToastrService
  ) {

    this.getConfiguracion();
    this.getPedidosFechaActual();

    this.ws.getImgBanner().then((data: []) => {
      this.imgsBanner = data;
      // console.log("Banner", this.imgsBanner);
    });

    this.ws.getCategorias().then((data: []) => {
      this.categorias = data;
      // console.log('Categorias', this.categorias);
      (this.categorias[0]) ? this.listarProductos(this.categorias[0].id) : this.loading = false;
    });

    this.ws.getTiposPrecio().then((dataPrecio) => {
      this.tipoPrecio = dataPrecio;
    });

    if (!this.appContext.Session.Gapi.isSignedIn) {
      this.signIn();
    }

    this.localStorage.getItem('admin').subscribe((data) => {
      if (data) {
        (data.estado == 'OK') ? this.login.estado = true : this.login.estado = false;
      }
    });

  }

  ngOnInit() {
    this.getDatos();
    this.getAdministracion();
    this.getPromociones();
  }

  signIn() {

    this.appContext.Session.Gapi.signIn().then(() => {
      console.log("Logeo google");
    })

  }

  logearLocal() {
    if (this.login.user && this.login.passw) {
      if (this.administracion.usuario == this.login.user && this.administracion.password == this.login.passw) {
        this.login.estado = true;
        this.localStorage.setItem('admin', { estado: 'OK' }).subscribe((data) => {
          this.toaster.success('Bienvenido!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
        });
      } else {
        this.toaster.error('Credenciales no válidas!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
      }
    } else {
      this.toaster.error('Complete los campos!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    }
  }

  eliminarFoto(id_img_banner) {
    this.loading = true;
    this.ws.deleteImgBanner(id_img_banner).then((data) => {
      this.ws.getImgBanner().then((data: []) => {
        this.imgsBanner = data;
        this.loading = false;
        this.toaster.success('Imagen eliminada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
      })
    })
  }

  actualizarInformacion() {
    this.loading = true;
    this.ws.putInformacion(this.informacion).then((data) => {
      this.getDatos();
      this.loading = false;
      this.toaster.success('Información actualizada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    });
  }

  actualizarAdministracion() {
    this.loading = true;
    this.ws.putAdministracion(this.administracion).then((data) => {
      this.getAdministracion();
      this.loading = false;
      this.toaster.success('Información actualizada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    })
  }

  getDatos() {
    this.loading = true;
    this.ws.getInformacion().then((data) => {
      this.informacion = data[0];
      // console.log(this.informacion);
      this.loading = false;
    })
  }

  getAdministracion() {
    this.loading = true;
    this.ws.getAdministracion().then((data) => {
      this.administracion = data[0];
      this.loading = false;
      // console.log("Administracion", this.administracion);
    })
  }

  getPromociones() {
    this.loading = true;
    this.ws.getPromociones().then((data: any) => {
      this.promociones = data;
      // console.log("Promciones", this.promociones);
      this.loading = false;
    })
  }

  eliminarPromocion(id_promocion) {
    this.loading = true;
    this.ws.deletePromocion(id_promocion).then((data) => {
      this.loading = false;
      this.getPromociones();
      this.toaster.success('Imagen eliminada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    });
  }

  testing() {
    //console.log("heyheyhey");
  }

  listarProductos(grupo_id) {
    this.categoria = grupo_id;
    this.loading = true;
    this.ws.getProductosPorGrupo(grupo_id).then((data: []) => {
      this.productos = this.eliminarDuplicados(data, 'pro_cod');
      // console.log(this.productos);
      this.loading = false;
    });
  }

  eliminarDuplicados(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  getPedidos() {
    let ped = [];
    this.ws.getPedidos().then((data: any) => {
      // console.log("pedidos", data);
      data.forEach(f => {
        if (f.estado_transaccion != 'PENDING') {
          ped.push(f);
        }
      });
      this.pedidos = this.ordenarPedidosPorFecha(ped);
    });
  }

  getPedidosFechaActual() {
    this.pedidos = [];
    let date = new Date();
    let fechActual = date.getFullYear() + '/' + this.meses[date.getMonth()] + '/' + date.getDate();
    let timeFechActual = (Date.parse(fechActual + 'GMT-0500') / 1000);
    // let timeFechActual = (Date.parse(new Date() + 'GMT-0500') / 1000);
    // console.log("Actual", fechActual);
    // console.log("Time actual", timeFechActual);
    this.ws.getPedidos().then((data: any) => {
      data.forEach(f => {
        // console.log(f.estado_transaccion);
        if (f.estado_transaccion != 'PENDING') {
          let time = Date.parse(f.fecha_pedido + 'GMT-0500') / 1000;
          if (timeFechActual == time) {
            this.pedidos.push(f);
          }
        }
      });
    })
  }

  ordenarPedidosPorFecha(pedidos) {
    pedidos.forEach(f => {
      let time = Date.parse(f.fecha_pedido + 'GMT-0500') / 1000;
      if (time) {
        f.fechaTime = time;
      } else {
        f.fechaTime = 1514853535;
      }
    });
    return pedidos.sort(((a, b) => b.fechaTime - a.fechaTime));
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getPedidosDetalle(pedido, mostrarDetallePedido) {
    // console.log(pedido);
    this.costosDetallePedido = {
      totalPedido: 0.0,
      totalPagar: 0.0,
      costoEnvio: 0,
      porcentajeCompraTarjeta: 0,
      porcentajeCodigoDescuento: 0,
      descuentoCodigo: 0,
      recargoTarjeta: 0
    }

    this.costosDetallePedido.totalPedido = pedido.total;
    this.costosDetallePedido.costoEnvio = pedido.costoEnvio;
    this.costosDetallePedido.porcentajeCompraTarjeta = pedido.porcentajeCompraTarjeta;
    this.costosDetallePedido.porcentajeCodigoDescuento = pedido.porcentajeCodigoDescuento;
    this.costosDetallePedido.descuentoCodigo = pedido.descuentoCodigo;
    this.costosDetallePedido.recargoTarjeta = pedido.recargoTarjeta;
    this.costosDetallePedido.totalPagar = pedido.totalPagar;

    this.ws.getPedidosDetalle(pedido.id_pedido).then((detallePedido: any) => {
      this.detallePedidos = detallePedido;
      this.ws.getPedidoId(pedido.id_pedido).then((dataPedido: []) => {
        this.pedidoEspecifico = dataPedido;
      });
      this.modalCtrl.open(mostrarDetallePedido, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  actualizarPedido(estado, pedido: any) {
    this.loading = true;
    delete pedido['fechaTime'];
    pedido.estado = estado.target.value;
    let id_pedido = pedido.id_pedido;
    this.ws.updatePedido(id_pedido, pedido).then((data) => {
      // this.getPedidos();
      // console.log("se actualizo");
      this.loading = false;
    });
  }

  eliminarPedido(p, deletePedido) {
    this.modalCtrl.open(deletePedido, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loading = true;
      this.ws.deletePedidoDetalle(p.id_pedido).then((res) => { 
        this.ws.deletePedido(p.id_pedido).then((res) => { 
          this.loading = false;
          this.getPedidosFechaActual();
        });
      });
      
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log("Cancelar");
    });
  }

  logout() {
    this.localStorage.removeItem('admin').subscribe((data) => {
      this.login.estado = false;
    })
  }

  administrarImagenesProductos(imagenesProducto, titImagen, idImagen, producto, t) {
    this.tituloImagen = titImagen;
    this.modalCtrl.open(imagenesProducto, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      // console.log(result);
      // console.log(idImagen);
      // console.log(producto);
      // console.log(t);
      this.processFile(result, idImagen, producto, t)
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // Imagenes de los produtos y banner
  processFile(imageInput: any, idImagen, producto, t) {
    this.loading = true;
    const file: File = imageInput.files[0];
    if (file) {
      this.toaster.warning('Espere un momento ..', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      const reader = new FileReader();
      let fileInfo = new FileInfo();
      fileInfo.Name = file.name;
      fileInfo.Blob = file;

      this.appContext.Repository.File.importFile(
        this.configuracion.urlBanner,
        fileInfo,

        (res) => this.onImportError(res),
        (res) => this.onImportComplete(res, idImagen, producto, t),
        (res) => this.onImportProgress(res)
      )
    } else {
      this.loading = false;
      this.toaster.error('Seleccione una imagen', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    }
  }

  onImportError(res) {
    //console.log("error", res) 
    this.loading = false;
    this.toaster.error('Intente nuevamente!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
  };

  onImportComplete(res, idImagen, producto, t) {
    //console.log("OK", res);
    this.toaster.success('Imágen guardada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    let data = JSON.parse(res);
    let url = data.id;
    if (t == "b") {
      let datos = {
        nombre: data.name,
        url: url
      }
      this.ws.guardarImgBanner(datos).then((data) => {
        //console.log(data);
        this.loading = false;
        this.listarProductos(this.categoria);
        this.ws.getImgBanner().then((data: []) => {
          this.imgsBanner = data;
        });
      });

    } else {
      this.ws.updateImgWeb(idImagen, url, producto).then((data: any) => {
        console.log('resp guardar imagen', data);
        this.loading = false;
        if (!data.error) {
          this.listarProductos(this.categoria);
          this.toaster.success('Imágen guardada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
        } else {
          this.appContext.Repository.File.delete(url).then(async (resDrive) => { console.log(resDrive);});
          this.toaster.error('Ha ocurrido un error', '', { timeOut: 3000, positionClass: 'toast-bottom-right' });
        }
      });
    }
  }

  onImportProgress(res) {
    //console.log("proceso",res) 
    // this.loading = false;
  };

  getConfiguracion() {
    this.loading = true;
    this.ws.getConfiguracion().then((data: any) => {
      this.loading = false;
      this.configuracion = data[0];
      // console.log("COnfiguracion", this.configuracion);
    });
  }

  updateNombreContenido() {
    if (this.configuracion.tituloPromocion) {
      this.loading = true;
      this.ws.updateConfiguracion(this.configuracion).then((data) => {
        this.getConfiguracion();
        this.loading = false;
        this.toaster.success('Nombre actualizado', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
      });
    } else {
      this.toaster.error('Campo vacio!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    }
  }

  // Imagenes de promociones
  crearPromocion(imageInput: any, nom_prod) {
    this.loading = true;
    const file: File = imageInput.files[0];
    if (file) {
      this.toaster.warning('Espere un momento ..', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      const reader = new FileReader();

      let fileInfo = new FileInfo();
      fileInfo.Name = file.name;
      fileInfo.Blob = file;

      this.appContext.Repository.File.importFile(
        this.configuracion.urlPromocion,
        fileInfo,
        (res) => this.onImportError(res),
        (res) => this.imgPromocionComplete(res, nom_prod),
        (res) => this.onImportProgress(res)
      )
    } else {
      this.loading = false;
      this.toaster.error('Seleccione una imagen!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    }
  }

  imgPromocionComplete(res, nom_prod) {
    //console.log(res);
    this.loading = false;
    let data = JSON.parse(res);
    let url = data.id;
    let datos = {
      nombrePromocion: nom_prod,
      nombreImagen: data.name,
      imgPromocion: url,
    }
    this.ws.insertImgPromocion(datos).then((data) => {
      this.getPromociones();
      this.toaster.success('Imágen guardada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    });

  }

  // Logo de la empresa
  upadteLogo(imageInput) {
    this.loading = true;
    const file: File = imageInput.files[0];
    if (file) {
      this.toaster.warning('Espere un momento ..', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      const reader = new FileReader();
      let fileInfo = new FileInfo();
      fileInfo.Name = file.name;
      fileInfo.Blob = file;

      this.appContext.Repository.File.importFile(
        this.configuracion.urlLogo,
        fileInfo,
        (res) => this.onImportError(res),
        (res) => this.imgLogo(res),
        (res) => this.onImportProgress(res)
      )
    } else {
      this.loading = false;
      this.toaster.error('Seleccione una imagen!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    }
  }

  imgLogo(res) {
    this.loading = true;
    let data = JSON.parse(res);
    this.configuracion.imgLogo = data.id;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.getConfiguracion();
      this.loading = false;
      this.toaster.success('Imágen guardada!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    });
  }

  updateColor(color) {
    if (color) {
      this.configuracion.colorPrincipal = color;
      this.loading = true;
      this.ws.updateConfiguracion(this.configuracion).then((data) => {
        this.loading = false;
        this.getConfiguracion();
        this.toaster.success('Color establecido', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
      });
    } else {
      this.toaster.error('Seleccione un color!', 'Error', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    }
  }

  updateTipoPrecio(p) {
    this.configuracion.tipoPrecio = p.target.value;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Tipo de precio actualizado', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });

  }

  updateValorMinimoCompra(valorMinimo) {
    if (parseFloat(valorMinimo)) {
      this.loading = true;
      this.configuracion.valor_minimo_compra = parseFloat(valorMinimo);
      this.ws.updateConfiguracion(this.configuracion).then((data) => {
        this.loading = false;
        this.getConfiguracion();
        this.toaster.success('Valor actualizado', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
      });
      // console.log(parseFloat(valorMinimo));
    } else {
      this.toaster.error('El dato debe ser númerico!', 'Error', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    }


  }

  updatePorcentajePrecioOferta(porcentajePrecioOferta) {
    this.configuracion.porcentajePrecioOferta = porcentajePrecioOferta;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Porcentaje actualizado', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });
  }

  updateTextoCostoEnvio(textoCostoEnvio, costoEnvio) {
    let c;
    if (costoEnvio) {
      c = costoEnvio;
    } else {
      c = 0;
    }
    this.configuracion.textoCostoEnvio = textoCostoEnvio;
    this.configuracion.costoEnvio = c;
    // console.log(this.configuracion);
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Informacion del costo del envío actualizada', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });
  }

  actualizarVisibilidadBtnWhatsapp(e) {
    this.loading = true;
    console.log(e.target.value);
    this.configuracion.visibilidadBtnWhatsapp = e.target.value;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Visualización del botón cambiado', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });
  }

  updateConfiguracionBtn1(textoBtn1, apliCostEnviBtn1) {
    // this.loading = true;
    this.configuracion.txtBtnEnviarPedido1 = textoBtn1;
    this.configuracion.aplicarCostoEnvioBtn1 = apliCostEnviBtn1;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Descripción del botón cambiado', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });
  }

  filtrarPedidosFecha() {
    let desde;
    let hasta;
    let fechIniciar = true;

    if (this.filtroFecha.desde) {
      desde = (Date.parse(this.filtroFecha.desde + 'GMT-0500') / 1000);
    } else {
      desde = 0;
    }
    if (this.filtroFecha.hasta) {
      hasta = (Date.parse(this.filtroFecha.hasta + 'GMT-0500') / 1000);
    } else {
      hasta = 0;
    }

    this.ws.getPedidos().then((data: any) => {
      let pedidoTemporal = this.ordenarPedidosPorFecha(data);
      this.pedidos = [];
      pedidoTemporal.forEach(f => {
        let time = Date.parse(f.fecha_pedido + 'GMT-0500') / 1000;
        if (desde > 0 && hasta > 0) {
          if (time) {
            if (time >= desde && time <= hasta) {
              this.pedidos.push(f)
            }
          }
        } else {
          if (desde > 0) {
            if (time == desde) {
              this.pedidos.push(f)
            }
          } else {
            fechIniciar = false;
          }
        }
      });

      if (fechIniciar == true) {
        if (this.pedidos.length == 0) {
          this.toaster.warning('No se encontro resultados', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
        }
      } else {
        this.toaster.error('Seleccione la Fecha Inicial', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
      }

    });
    // console.log("Desde", desde);
    // console.log("Hasta", hasta);
    // console.log("Desde", new Date((desde) * 1000));
    // console.log("Hasta", new Date((hasta) * 1000));
  }

  filtrarPedidosEstado(e) {
    console.log(e.target.value);
    this.ws.getPedidos().then((data: any) => {
      // console.log(data);
      let pedidoTemporal = this.ordenarPedidosPorFecha(data);
      this.pedidos = [];
      pedidoTemporal.forEach(f => {
        if (f.estado == e.target.value) {
          this.pedidos.push(f)
        }
      });
    });
  }

  updateCompraTarjetaC(porcentajeCompraTarjeta, txtRecargoTarjetC) {
    this.loading = true;
    if (porcentajeCompraTarjeta) {
      this.configuracion.porcentajeCompraTarjeta = porcentajeCompraTarjeta;
    } else {
      this.configuracion.porcentajeCompraTarjeta = 0;
    }
    this.configuracion.txtRecargoTarjetaC = txtRecargoTarjetC;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Datos actualizados exitosamente.', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });
  }

  aplicarCostoEnvioBtn1(e) {
    this.configuracion.aplicarCostoEnvioBtn1 = e.target.value;
  }

  updateporcentajeCodigoDescuento(txtCodigoDescuento, porcentajeCodigoDescuento) {
    this.loading = true;
    if (porcentajeCodigoDescuento) {
      this.configuracion.porcentajeCodigoDescuento = porcentajeCodigoDescuento;
    } else {
      this.configuracion.porcentajeCodigoDescuento = 0;
    }
    this.configuracion.txtCodigoDescuento = txtCodigoDescuento;
    this.ws.updateConfiguracion(this.configuracion).then((data) => {
      // console.log("respuesta actualizacion configuracion", data);
      this.loading = false;
      this.getConfiguracion();
      this.toaster.success('Informacion actualizada exitosamente', 'Correcto', { timeOut: 1000, positionClass: 'toast-bottom-right' });
    });

  }
}
