import { Injectable } from '@angular/core';
// import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database'
import { AngularFirestore } from '@angular/fire/firestore';

import { Subject, from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';

import 'rxjs/Rx';

// Libreria para generar el SHA-1 y Base 64
var Hashes = require('jshashes')

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'image/jpeg'
//   })
// };

export interface productoCarrito {
  id_prod: number,
  nombre: string,
  precio: number,
  cantidad: number,
  img_web: string,
  stockReal: number
}


@Injectable({
  providedIn: 'root'
})

export class WebService {

  public carrito = [];
  private cart$ = new Subject<productoCarrito[]>();
  public totalCarrito = 0.00;
  public id_pedido = [];
  public html = "";

  public stockDisponible = [];

  public urlBase = "https://sofpymes.com/puntopymes/common/movil/";
  public urlLocal = "https://www.pulpoplace.com:8448/";
  // public urlLocal = "http://localhost:3010/";
  // public urlCors = 'https://cors-anywhere.herokuapp.com/';
  public urlCors = 'http://45.76.250.195:8090/';
  public id_empresa = 31;

  // productList: AngularFireList<any>;

  constructor(
    private http: HttpClient,
    private firebase: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toaster: ToastrService
  ) {
  }

  quitarDelCarrito(p) {
    for (let i = 0; i < this.carrito.length; i++) {
      let index = this.carrito.indexOf(p);
      index !== -1 && this.carrito.splice(index, 1);
      this.cart$.next(this.carrito);
    }
  }

  vaciarCarrito() {
    // console.log("LLegoooo a vaciar");
    this.carrito = [];
  }

  // 1 = flujo normal de compra
  // 0 = Cuando se guarda en Local Storage y se recarga la pagina
  agregarAlCarrito(producto: productoCarrito, tipo) {
    var existeProducto = false;
    this.carrito.forEach(c => {
      if (c.id_prod == producto.id_prod) {
        existeProducto = true;
      }
    });

    if (existeProducto) {
      console.log("Sumar Cantidad");
      this.carrito.forEach(c => {
        if (c.id_prod == producto.id_prod) {
          if (c.cantidad < c.stockReal) {
            c.cantidad = parseInt(c.cantidad) + 1;
            if (tipo == 1) {
              this.toaster.success(c.nombre + ', agregado al carrito', '', { timeOut: 2000, positionClass: 'toast-bottom-left' });
            }
          } else {
            this.toaster.error('Cantidad no disponible, stock agotado', '', { timeOut: 2000, positionClass: 'toast-bottom-left' });
          }
        }
      });
    } else {
      console.log("Add al carrito");
      this.carrito.push(producto);
      if (tipo == 1) {
        this.toaster.success(producto.nombre + ', agregado al carrito', '', { timeOut: 2000, positionClass: 'toast-bottom-left' });
      }
      this.cart$.next(this.carrito);
      this.totalCarrito = 0.00
      this.carrito.forEach(item => {
        this.totalCarrito += (parseInt(item.cantidad) * parseFloat(item.precio));
      });
    }
  }

  getTotal() {
    this.totalCarrito = 0;
    this.carrito.forEach(item => {
      this.totalCarrito += (parseInt(item.cantidad) * parseFloat(item.precio));
    });
    return this.totalCarrito;
  }

  getCarrito() {
    return this.carrito;
  }

  getNumeroProductosCarrito(): Observable<productoCarrito[]> {
    return this.cart$.asObservable();
  }

  getAdministracion() {
    let url = this.urlLocal + "administracions/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  putAdministracion(administracion: any) {
    let url = this.urlLocal + "administracions";
    return new Promise((resolve) => {
      this.http.put(url, administracion)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  getImgProductos() {
    let url = this.urlLocal + "imagenes-productos"
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  getInformacion() {
    let url = this.urlLocal + "informacions/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  putInformacion(informacion: any) {
    let url = this.urlLocal + "informacions/" + informacion.id_informacion;
    return new Promise((resolve) => {
      this.http.put(url, informacion)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  getImgBanner() {
    let url = this.urlLocal + "imagenes-banners/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("banner", data);
          resolve(data);
        });
    })
  }

  guardarImgBanner(imgBanner) {
    imgBanner.id_empresa = this.id_empresa;
    let url = this.urlLocal + "imagenes-banners";
    return new Promise((resolve) => {
      this.http.post(url, imgBanner)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  deleteImgBanner(id_img_banner) {
    let url = this.urlLocal + "imagenes-banners/" + id_img_banner;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  // Obtiene las categorias
  getCategorias() {
    let url = this.urlBase + "grupos_productos_all"
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("Categorias", data.query);
          if (data.query.length == 0) {
            this.toaster.warning('No se ha encontrado Productos', '', { timeOut: 2000, positionClass: 'toast-bottom-center' });
          }
          resolve((data.query));
        });
    })
  }

  loginCliente(user, pwd) {
    let url = this.urlBase + "login_web_cliente?usuario=" + user + "&clave=" + pwd;
    // console.log(url);
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data.query));
        });
    })
  }

  // Obtiene los productos
  getProductosPorGrupo(idGrupo) {
    // console.log(idGrupo);
    let url = this.urlBase + "productosall_x_grupoid?grupo_id=" + idGrupo;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("Productos categoria", data.query);
          resolve((data.query));
        });
    })
  }

  obtenerImagenPrincipalProducto(arrImagenes) {

    let img_web;
    let imgProd = false;
    for (var i = 0; i < arrImagenes.length; i++) {
      if (imgProd == false) {
        if (arrImagenes[i].imagen) {
          img_web = arrImagenes[i].imagen;
          imgProd = true;
        } else {
          img_web = '';
        }
      }
    }
    return img_web;
  }

  getProductosBusqueda(filtro) {
    let url = this.urlBase + "productosall_x_letras?prod_letras=" + filtro
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("Productos Busqueda", data);
          resolve((data.query));
        });
    })
  }

  updateImgWeb(idImagen, urlImagen, producto) {
    let data;
    switch (idImagen) {
      case 'imagen_uno': {
        data = {
          'prod_id': producto.pro_cod,
          'imagen_uno': urlImagen,
          'imagen_dos': producto.imagen_dos,
          'imagen_tres': producto.imagen_tres,
          'imagen_cuatro': producto.imagen_cuatro
        }
        break;
      }
      case 'imagen_dos': {
        data = {
          'prod_id': producto.pro_cod,
          'imagen_uno': producto.imagen_uno,
          'imagen_dos': urlImagen,
          'imagen_tres': producto.imagen_tres,
          'imagen_cuatro': producto.imagen_cuatro
        }
        break;
      }
      case 'imagen_tres': {
        data = {
          'prod_id': producto.pro_cod,
          'imagen_uno': producto.imagen_uno,
          'imagen_dos': producto.imagen_dos,
          'imagen_tres': urlImagen,
          'imagen_cuatro': producto.imagen_cuatro
        }
        break;
      }
      case 'imagen_cuatro': {
        data = {
          'prod_id': producto.pro_cod,
          'imagen_uno': producto.imagen_uno,
          'imagen_dos': producto.imagen_dos,
          'imagen_tres': producto.imagen_tres,
          'imagen_cuatro': urlImagen
        }
        break;
      }
    }


    let send = {
      data: data,
      endPoint: this.urlBase + "update_img"
    }

    // console.log('data enviar servicio', JSON.stringify(send));

    // let url = this.urlCors + this.urlBase + "update_img";
    let url = this.urlLocal + "billing/updateImgWeb";
    return new Promise((resolve) => {
      this.http.post(url, send)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  uploadImage(image: any) {
    // let url = "http://localhost/testPHP/testing.php"
    let url = "http://45.71.114.70/makingpcsa/images/testing.php"
    return new Promise((resolve) => {
      this.http.post(url, image, { responseType: 'text' }).subscribe((data: any) => {
        resolve(data);
      });
    })
  }

  getProductoCodigo(pro_cod) {
    let url = this.urlBase + "producto_x_id?pro_cod=" + pro_cod;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("Producto codigo ws", data);
          resolve((data));
        });
    })
  }

  getPedidos() {
    let url = this.urlLocal + "pedidosEmpresa/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  getPedidoId(id_pedido) {
    let url = this.urlLocal + "pedidos/" + id_pedido;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  insertPedido(pedido) {
    pedido.id_empresa = this.id_empresa;
    // console.log("Datos del pedido", pedido);
    let url = this.urlLocal + "pedidos";
    return new Promise((resolve) => {
      this.http.post(url, pedido)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  deletePedido(id_pedido) {
    let url = this.urlLocal + "pedidos/" + id_pedido;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  updatePedido(id_pedido, pedido: any) {
    let url = this.urlLocal + "pedidos/" + id_pedido;
    return new Promise((resolve) => {
      this.http.put(url, pedido)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  insertPedidosDetalle(detalle) {
    let url = this.urlLocal + "pedidos-detalles/"
    // console.log("Detalle del pedido", detalle);
    return new Promise((resolve) => {
      this.http.post(url, detalle)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  enviarMail(pedido, id_pedido, tipoPedido, nombreEmpresa, carrito, configuracion, codigoDescuento) {
    // console.log("id_pedido", id_pedido);

    this.html = '<table style="font-family:arial; border:1px solid #e8e6e6; border-top:none; border-bottom:none; border-spacing:0; max-width:600px; color:#707173; border-radius:40px;" align="center">';

    // Imagen de la cabecera
    this.html += '<thead>';
    this.html += '<tr>';
    this.html += '<td style="padding:0">';
    this.html += '<img style="width:100%; border-radius:20px 20px 0 0" src="https://drive.google.com/uc?export=view&id=1IPFTQvZ0W8OVzZRayypTKDExvMPjJyVm">';
    this.html += '</td>';
    this.html += '</tr>';
    this.html += '</thead>';

    this.html += '<tbody>'

    // Texto del encabezado
    this.html += '<tr>';
    this.html += '<td style="font-size:20px; text-align:center; padding:10px 0 8px 0; display:block">';
    this.html += '<span>Hola, </span>';
    this.html += '<strong><span>' + pedido.nombres + '</span></strong>';
    this.html += '</td>';
    this.html += '</tr>';

    this.html += '<tr>';
    this.html += '<td style="font-size:23px; text-align:center; padding:10px 15px 25px 15px;display:block">';
    this.html += '<span>Tu compra en la Tienda en línea </span>';
    this.html += '<strong><span> ' + nombreEmpresa + ' </span></strong>';
    this.html += '<span> ha sido </span>';
    this.html += '<span style="color:#7dd855; text-transform:uppercase; font-size:25px; margin-top:3px"> Exitosa</span>';
    this.html += '</td>';
    this.html += '</tr>';

    // Encabezado datos de factura
    this.html += '<tr>';
    this.html += '<td';
    this.html += '<div width="100%">'
    this.html += '<table style="font-family:arial; border:1px solid #e8e6e6; color:#707173; padding: 5px;" align="center" width="95%">';

    this.html += '<tr>';
    this.html += '<td><strong>Cliente: </strong><span>' + pedido.nombres + ' ' + pedido.apellidos + '</span></td>';
    this.html += '<td><strong>Fecha: </strong><span>' + pedido.fecha_pedido + '</span></td>';
    this.html += '</tr>';

    this.html += '<tr>';
    this.html += '<td><strong>Cédula/Ruc: </strong><span>' + pedido.cedula_cliente + '</span></td>';
    this.html += '<td><strong>Ciudad: </strong><span>' + pedido.ciudad + '</span></td>';
    this.html += '</tr>';

    this.html += '<tr>';
    this.html += '<td><strong>Teléfono: </strong><span>' + pedido.telefono + '</span></td>';
    this.html += '<td><strong>Dirección: </strong><span>' + pedido.direccion_cliente + '</span></td>';
    this.html += '</tr>';

    this.html += '</table>';
    this.html += '</div>'
    this.html += '</td>';
    this.html += '</tr>';


    // Texto Detalle del pedido
    this.html += '<tr>';
    this.html += '<td style="font-size: 17px; color:#707173; text-align: center; padding: 10px 0;">';
    this.html += '<span><strong>Detalle del Pedido</strong></span>';
    this.html += '</td>';
    this.html += '</tr>';


    // Detalle productos del pedido
    this.html += '<tr>';
    this.html += '<td>';
    this.html += '<div width="100%">'
    this.html += '<table style="font-family:arial; border:1px solid #e8e6e6; border-collapse:collapse; color:#707173; margin-bottom: 10px;" align="center" width="95%">'

    this.html += '<tr>';
    this.html += '<td style="border:1px solid #e8e6e6;">Cant.</td>';
    this.html += '<td style="border:1px solid #e8e6e6;">Cod.</td>';
    this.html += '<td style="border:1px solid #e8e6e6;">Detalle</td>';
    this.html += '<td style="border:1px solid #e8e6e6;">P.Unitario</td>';
    this.html += '<td style="border:1px solid #e8e6e6;">P.Total</td>';
    this.html += '</tr>';

    carrito.forEach(det => {
      this.html += '<tr>';
      this.html += '<td> ' + det.cantidad + ' </td>';
      this.html += '<td> ' + det.id_prod + ' </td>';
      this.html += '<td> ' + det.nombre + ' </td>';
      this.html += '<td> ' + det.precio + ' </td>';
      this.html += '<td> ' + (det.precio * det.cantidad) + ' </td>';
      this.html += '</tr>';
    });

    this.html += '</table>';
    this.html += '</div>'
    this.html += '</td>';
    this.html += '</tr>';


    // Totales y descuentos
    this.html += '<tr>';
    this.html += '<td>';
    this.html += '<div width="100%">';
    this.html += '<table style="font-family:arial; font-size: 14px; color:#707173; margin-bottom: 10px;" align="center" width="95%">';

    if (tipoPedido != 1) {
      if (tipoPedido == 4 || tipoPedido == 6 || tipoPedido == 7) {

        this.html += '<tr>';
        this.html += '<td>';
        this.html += '<strong>Total Compra: </strong><span> ' + pedido.total + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        this.html += '<tr>';
        this.html += '<td>';
        this.html += '<strong> ' + configuracion.txtRecargoTarjetaC + ' : </strong><span> ' + pedido.recargoTarjeta + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        if (codigoDescuento.aplicado == true) {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código </strong><span> ( ' + codigoDescuento.codigo + ' ): ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        } else {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código: </strong><span> ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        }

        this.html += '<tr>';
        this.html += '<td style="padding-bottom: 10px;">';
        this.html += '<strong>Costo de envío: </strong><span> ' + pedido.costoEnvio + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        this.html += '<tr style="text-align: center; font-size: 16px; background-color: #f31149;">';
        this.html += '<td style="border:1px solid #e8e6e6; padding: 5px; color: white;">';
        this.html += '<strong>Total a Pagar: </strong><span> ' + pedido.totalPagar + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

      } else {

        this.html += '<tr>';
        this.html += '<td>';
        this.html += ' <strong>Total Compra: </strong><span> ' + pedido.total + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        if (codigoDescuento.aplicado == true) {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código </strong><span> ( ' + codigoDescuento.codigo + ' ): ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        } else {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código: </strong><span> ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        }

        this.html += '<tr>';
        this.html += '<td style="padding-bottom: 10px;">';
        this.html += '<strong>Costo de envío: </strong><span> ' + pedido.costoEnvio + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        this.html += '<tr style="text-align: center; font-size: 16px; background-color: #f31149;">';
        this.html += '<td style="border:1px solid #e8e6e6; padding: 5px; color: white;">';
        this.html += '<strong>Total a Pagar: </strong><span> ' + pedido.totalPagar + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';
      }
    } else {
      if (configuracion.aplicarCostoEnvioBtn1 == 0) {

        this.html += '<tr>';
        this.html += '<td>';
        this.html += '<strong>Total Compra: </strong><span> ' + pedido.total + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        if (codigoDescuento.aplicado == true) {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código </strong><span> ( ' + codigoDescuento.codigo + ' ): ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        } else {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código: </strong><span> ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        }

        this.html += '<tr style="text-align: center; font-size: 16px; background-color: #f31149;">';
        this.html += '<td style="border:1px solid #e8e6e6; padding: 5px; color: white;">';
        this.html += '<strong>Total a Pagar: </strong><span> ' + pedido.totalPagar + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

      } else {

        this.html += '<tr>';
        this.html += '<td>';
        this.html += '<strong>Total Compra: </strong><span> ' + pedido.total + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        if (codigoDescuento.aplicado == true) {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código </strong><span> ( ' + codigoDescuento.codigo + ' ): ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        } else {
          this.html += '<tr>';
          this.html += '<td>';
          this.html += '<strong>Descuento x Código: </strong><span> ' + pedido.descuentoCodigo + '</span>';
          this.html += '</td>';
          this.html += '</tr>';
        }

        this.html += '<tr>';
        this.html += '<td>';
        this.html += '<strong>Costo de envío: </strong><span> ' + pedido.costoEnvio + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

        this.html += '<tr style="text-align: center; font-size: 16px; background-color: #f31149;">';
        this.html += '<td style="border:1px solid #e8e6e6; padding: 5px; color: white;">';
        this.html += '<strong>Total a Pagar: </strong><span> ' + pedido.totalPagar + ' </span>';
        this.html += '</td>';
        this.html += '</tr>';

      }
    }

    this.html += '</table>';
    this.html += '</div>'
    this.html += '</td>';
    this.html += '</tr>';

    // Nota y referencia de Compra
    this.html += '<tr>';
    this.html += '<td>';
    this.html += '<div width="100%">';
    this.html += '<table style="font-family:arial; font-size: 14px; color:#707173; text-align: center;" align="center" width="95%">';

    this.html += '<tr>';
    this.html += '<td style="font-size: 18px; padding-bottom: 10px;">';
    this.html += '<strong><span>La referencia de su compra es: ' + id_pedido + ' </span></strong>';
    this.html += '</td>';
    this.html += '</tr>';


    if (tipoPedido == 1) {
      this.html += '<tr>';
      this.html += '<td>';
      this.html += '<strong>Nota: <span>' + configuracion.txtBtnEnviarPedido1 + ' </span></strong>';
      this.html += '</td>';
      this.html += '</tr>';
    }

    if (tipoPedido == 2) {
      this.html += '<tr>';
      this.html += '<td>';
      this.html += '<strong>Nota: <span> El cliente ha seleccionado hacer el pago por medio de depósito o transferencia bancaría. </span></strong>';
      this.html += '</td>';
      this.html += '</tr>';
    }

    if (tipoPedido == 3) {
      this.html += '<tr>';
      this.html += '<td>';
      this.html += '<strong>Nota: <span> El cliente realizará el pago al momento de su entrega. </span></strong>';
      this.html += '</td>';
      this.html += '</tr>';
    }

    if (tipoPedido == 4) {
      this.html += '<tr>';
      this.html += '<td>';
      this.html += '<strong>Nota: <span> La compra ha sido por medio de Payphone. </span></strong>';
      this.html += '</td>';
      this.html += '</tr>';
    }

    if (tipoPedido == 5) {
      this.html += '<tr>';
      this.html += '<td>';
      this.html += '<strong>Nota: <span> La compra ha sido enviada por medio de WhatsApp. </span></strong>';
      this.html += '</td>';
      this.html += '</tr>';
    }

    if (tipoPedido == 6) {
      this.html += '<tr>';
      this.html += '<td>';
      this.html += '<strong>Nota: <span> La compra ha sido por medio de Data Fast. </span></strong>';
      this.html += '</td>';
      this.html += '</tr>';
    }

    this.html += '</table>';
    this.html += '</div>'
    this.html += '</td>';
    this.html += '</tr>';

    // Sugrenecia y Contactos
    this.html += '<tr>';
    this.html += '<td>';
    this.html += '<div width="100%">';
    this.html += '<table style="font-family:arial; border:1px solid #e8e6e6; border-radius: 25px; font-size: 14px; color:#707173; text-align: center; margin-top: 10px; margin-bottom: 15px; padding: 5px;" align="center" width="95%">';

    this.html += '<tr>';
    this.html += '<td>';
    this.html += '<span> Si tienes alguna pregunta sobre tu compra, te recomendamos contactarte con un asesor del comercio. </span>';
    this.html += '</td>';
    this.html += '</tr>';

    this.html += '<tr>';
    this.html += '<td>';
    this.html += '<span>';
    this.html += '<strong>E-mail: </strong>';
    this.html += '<a href="mailto:' + configuracion.correo_personal_api + '" target="_blank">' + configuracion.correo_personal_api + '</a>';
    this.html += '</span>';
    this.html += '</td>';
    this.html += '</tr>';


    this.html += '</table>';
    this.html += '</div>'
    this.html += '</td>';
    this.html += '</tr>';


    this.html += '</tbody>'

    this.html += '</table>';

    // console.log(this.html);


    let dataEmail = {
      h: this.html,
      correo_api: configuracion.correo_api,
      password_correo_api: configuracion.password_correo_api,
      correo_personal_api: configuracion.correo_personal_api,
      receptor: pedido.correo
    }


    // console.log("data email", data);
    let url = this.urlLocal + "enviarCorreo";
    return new Promise((resolve) => {
      this.http.post(url, dataEmail)
        .subscribe((data: any) => {
          console.log("respuesta correo", data);
          resolve(data);
        });
    });
  }

  enviarMailSoporte(data) {
    let url = this.urlLocal + "correoSoporte"
    return new Promise((resolve) => {
      this.http.post(url, data)
        .subscribe((data: any) => {
          resolve(data);
        });
    })

  }

  getConfiguracion() {
    let url = this.urlLocal + "configuracion/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("Configuracion", data);
          resolve(data);
        });
    });
  }

  getPromociones() {
    let url = this.urlLocal + "promociones/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log('promociones', data);
          resolve(data);
        });
    })
  }

  deletePromocion(id_promocion) {
    let url = this.urlLocal + "promociones/" + id_promocion;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  insertImgPromocion(data: any) {
    data.id_empresa = this.id_empresa;
    let url = this.urlLocal + "promociones"
    return new Promise((resolve) => {
      this.http.post(url, data)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  updateConfiguracion(data: any) {
    let url = this.urlLocal + "configuracion/" + data.id_configuracion;
    return new Promise((resolve) => {
      this.http.put(url, data)
        .subscribe((data: any) => {
          resolve(data);
        });
    })
  }

  getTiposPrecio() {
    let url = this.urlBase + "get_tipos_pvp?grupo_id=";
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data.query));
        });
    })
  }

  getPedidosDetalle(id_pedido) {
    let url = this.urlLocal + "pedidos-detalles/" + id_pedido;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("pedido detalles", data);
          resolve(data);
        });
    })
  }

  deletePedidoDetalle(id_pedido) {
    let url = this.urlLocal + "pedidos-detalles/" + id_pedido;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  comprobarCuentaPayphone(data, payphone) {
    // console.log("data payphone", payphone);
    let url = payphone.url + 'api/Users/' + data.number + '/region/' + data.prefix;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': payphone.token
    });
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: headers }).subscribe((data) => {
        resolve(data);
      },
        error => {
          resolve(error)
        });
    });
  }

  enviarPagoPayphone(dataCompra, payphone) {
    let url = payphone.url + '/api/Sale';
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': payphone.token
    });

    // return this.http.post(url, dataCompra, { headers: headers }).map((res: Response) => res);
    return new Promise((resolve, reject) => {
      this.http.post(url, dataCompra, { headers: headers }).subscribe((data) => {
        resolve(data);
      },
        error => {
          resolve(error)
        });
    });
  }

  comprobarEstadoCompra(idCompra, payphone) {
    let url = payphone.url + '/api/Sale/' + idCompra;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': payphone.token
    });
    return this.http.get(url, { headers: headers }).map((res: Response) => res);
  }

  cancelarCompraPayphone(id_transaccion, payphone) {
    let url = payphone.url + '/api/Cancel';
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': payphone.token
    });

    return new Promise((resolve, reject) => {
      this.http.post(url, id_transaccion, { headers: headers }).subscribe((data) => {
        resolve(data);
      },
        error => {
          resolve(error)
        });
    });
  }

  validarDocumentoIdentidad(documentoIdentidad) {
    let url = this.urlBase + "validar_cedula?ci=" + documentoIdentidad;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        });
    });
  }

  validarCorreo(email) {
    let expr = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
    if (expr.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  validadSoloLetras(texto) {
    // return texto.search(/[^a-zA-Z]+$/);
    for (var x = 0; x < texto.length; x++) {
      var c = texto.charAt(x);
      // Si no está entre a y z, ni entre A y Z, ni es un espacio
      if (!((c >= 'a' && c <= 'z') || (c >= 'A' && c <= 'Z') || c == ' ')) {
        return false;
      }
    }
    return true;
  }

  validarSoloNumeros(numero) {
    return numero.search(/^([0-9])*$/);
  }

  crearArregloStock(stock) {
    this.stockDisponible = [];
    for (var i = 1; i <= stock; i++) {
      this.stockDisponible.push(i);
    }
    return this.stockDisponible;
  }

  verificarCodigoDescuento(codigoDescuento) {
    let data = {
      id: codigoDescuento,
      endPoint: this.urlBase + "existe_vendedor"
    }

    let url = this.urlLocal + "billing/codigoDescuento";
    return new Promise((resolve) => {
      this.http.post(url, data)
        .subscribe((data: any) => {
          resolve(data.respuesta);
        });
    })
  }

  // Data fast
  getCheckOutIdDataFast(dataFast, valorCompra, datosTransaccion) {
    var data: any;
    let url = this.urlLocal + 'datafast/checkOutId';

    data = {
      "authentication.entityId": dataFast.secret_key,
      "amount": valorCompra,
      "currency": 'USD',
      "paymentType": 'DB',
      "customer.givenName": datosTransaccion.customer.givenName,
      "customer.surname": datosTransaccion.customer.surname,
      "customer.ip": datosTransaccion.customer.ip,
      "customer.merchantCustomerId": datosTransaccion.customer.identificationDocId,
      "customer.email": datosTransaccion.customer.email,
      "customer.identificationDocType": datosTransaccion.customer.identificationDocType,
      "customer.identificationDocId": datosTransaccion.customer.identificationDocId,
      "customer.phone": datosTransaccion.customer.phone,
      "merchantTransactionId": datosTransaccion.merchantTransactionId,
      "shipping.street1": datosTransaccion.shipping.street1,
      "shipping.country": datosTransaccion.shipping.country,
      "billing.street1": datosTransaccion.billing.street1,
      "billing.country": datosTransaccion.billing.country,
      "customParameters[4300000386_BP349696]": datosTransaccion.customParameters,
      "risk.parameters[USER_DATA2]": datosTransaccion.risk.parameters,
    }

    for (var i = 0; i < datosTransaccion.cart.items.length; i++) {
      data['cart.items[' + i + '].name'] = datosTransaccion.cart.items[i].name;
      data['cart.items[' + i + '].description'] = datosTransaccion.cart.items[i].description;
      data['cart.items[' + i + '].price'] = datosTransaccion.cart.items[i].price;
      data['cart.items[' + i + '].quantity'] = datosTransaccion.cart.items[i].quantity;
    }

    // console.log("Original", JSON.stringify(data));

    let transaccion = {
      endPoint: dataFast.url + '/v1/checkouts',
      token: dataFast.token,
      data: data
    }

    return new Promise((resolve, reject) => {
      this.http.post(url, transaccion).subscribe((res: any) => {
        resolve(res.respuesta);
      },
        error => {
          resolve(error)
        });
    });
  }

  // procesarTransaccionDataFast(dataFast, resourcePath) {
  //   // let url = this.urlCors + 'https://test.oppwa.com' + resourcePath + '?entityId=' + entityId;
  //   let url = this.urlCors + dataFast.url + resourcePath + '?entityId=' + dataFast.secret_key;

  //   let headers: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     'Authorization': dataFast.token,
  //   });
  //   return new Promise((resolve, reject) => {
  //     this.http.get(url, { headers: headers }).subscribe((data) => {
  //       resolve(data);
  //     },
  //       error => {
  //         resolve(error)
  //       });
  //   });

  // }

  estadoTransaccionDatafastIdPedido(dataFast, id_pedido) {
    let url = this.urlLocal + 'datafast/statusTransaccion';

    let transaccion = {
      endPoint: dataFast.url + '/v1/query?authentication.entityId=' + dataFast.secret_key + '&merchantTransactionId=' + id_pedido,
      token: dataFast.token
    }

    return new Promise((resolve, reject) => {
      this.http.post(url, transaccion).subscribe((data: any) => {
        resolve(data.respuesta);
      },
        error => {
          resolve(error)
        });
    });

    // // let url = this.urlCors + dataFast.url + '/v1/query?authentication.entityId=' + dataFast.secret_key + '&merchantTransactionId=' + id_pedido;
    // let url = dataFast.url + '/v1/query?authentication.entityId=' + dataFast.secret_key + '&merchantTransactionId=' + id_pedido;
    // // console.log(url);
    // let headers: HttpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   'Authorization': dataFast.token,
    // });
    // return new Promise((resolve, reject) => {
    //   this.http.get(url, { headers: headers }).subscribe((data) => {
    //     resolve(data);
    //   },
    //     error => {
    //       resolve(error)
    //     });
    // });
  }

  obtenerDireccionIp() {
    //let url = this.urlCors + 'http://gd.geobytes.com/GetCityDetails';
    let url = 'https://geolocation-db.com/json/';

    return new Promise((resolve) => {
      this.http.get(url).subscribe((data: any) => {
        data.geobytesipaddress = data.IPv4;
        resolve(data);
      },
        error => {
          resolve(error)
        });
    });

    // let url = this.urlLocal + 'billing/obtenerIp';

    // let data = {
    //   endPoint: "http://gd.geobytes.com/GetCityDetails"

    // }
    // return new Promise((resolve) => {
    //   this.http.post(url, data).subscribe((data: any) => {
    //     resolve(data.respuesta);
    //   },
    //     error => {
    //       resolve(error)
    //     });
    // });
  }

  // Fin Data Fast

  buscarClienteCedulaRuc(cedula) {
    let url = this.urlBase + "cliente_ci?identificacion=" + cedula;
    return new Promise((resolve) => {
      this.http.get(url).subscribe((data) => {
        resolve(data);
      },
        error => {
          resolve(error)
        });
    });
  }

  registrarClienteBilling(datosPersonales) {
    let url = this.urlLocal + "billing/registrarCliente";

    let es_pasaporte = 0;
    if (datosPersonales.cedula.length == 13) {
      es_pasaporte = 1;
    }

    let data = {
      endPoint: this.urlBase + "insert_cliente",
      user: {
        cedula: datosPersonales.cedula,
        nombres: datosPersonales.nombres,
        apellidos: datosPersonales.apellidos,
        razonsocial: datosPersonales.nombres + datosPersonales.apellidos,
        direccion: datosPersonales.direccion,
        provincia: datosPersonales.ciudad,
        email: datosPersonales.correo,
        celular: datosPersonales.telefono,
        es_pasaporte: es_pasaporte,
        telefonos: datosPersonales.telefono,
        tipoCli: "",
        vendedor_id: "",
        diasCredito: "",
        cupo_credito: ""
      }
    }

    return new Promise((resolve) => {
      this.http.post(url, data).subscribe((data: any) => {
        resolve(data.respuesta);
      },
        error => {
          resolve(error)
        });
    });
  }

  // Obtener los botones de pago que tiene la empresa
  obtenerBotonesPago() {
    let url = this.urlLocal + "pasarelaPago/botones/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }


  // Obtener todos los pedidos realizados por medio de Boton de pagaos
  getPedidosBtnPagosEmpresa(nombrePasarela) {
    let data = {
      pasarela_pago: nombrePasarela,
      id_empresa: this.id_empresa
    }
    return new Promise((resolve, reject) => {
      let url = this.urlLocal + 'pedidos/pasarelaTodos';
      this.http.post(url, data).subscribe((res) => {
        resolve(res);
      },
        error => {
          reject(error)
        });
    });
  }

  // tipo = 0 => Obtener Fecha normal
  obtenerFechaActual(tipo) {
    let fechActual;
    var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    const date = new Date();
    if (tipo == 0) {
      fechActual = date.getFullYear() + "/" + meses[date.getMonth()] + "/" + date.getDate();
    }
    if (tipo == 1) {
      date.setMinutes(date.getMinutes() + 10);
      date.setHours(date.getHours() - 5);
      fechActual = date.toISOString();
    }
    if (tipo == 2) {
      date.setHours(date.getHours() - 5);
      fechActual = date.toISOString();
    }
    return fechActual;
  }

  getPedidoXCedulaCliente(cedula) {
    let data = {
      cedula_cliente: cedula,
      id_empresa: this.id_empresa
    }
    return new Promise((resolve, reject) => {
      let url = this.urlLocal + 'pedidos/cedulaCliente';
      this.http.post(url, data).subscribe((res) => {
        resolve(res);
      },
        error => {
          reject(error)
        });
    });
  }

  async transaccionAprobadaEnviarCorreo(id_pedido, dataPedido, tipoPedido, configuracion) {
    let nombreEmpresa = '';
    let carrito = {};
    let codigoDescuento = {
      porcentaje: 0,
      codigo: '',
      aplicado: false,
    }

    await this.getInformacion().then((resInfo: any) => {
      nombreEmpresa = resInfo[0].nombre;
    });

    if (dataPedido.descuentoCodigo > 0) {
      codigoDescuento.porcentaje = dataPedido.porcentajeCodigoDescuento;
      codigoDescuento.codigo = dataPedido.numeroCodigoDescuento;
      codigoDescuento.aplicado = true;
    }

    await this.getPedidosDetalle(id_pedido).then(async (resDetalle: any) => {
      for (var i = 0; i < resDetalle.length; i++) {
        let prod: productoCarrito = {
          nombre: resDetalle[i].producto,
          cantidad: resDetalle[i].cantidad,
          id_prod: resDetalle[i].pro_cod,
          precio: resDetalle[i].precio_u,
          img_web: '',
          stockReal: 0
        };
        this.agregarAlCarrito(prod, 0);
      }

      carrito = this.getCarrito();
      await this.enviarMail(dataPedido, id_pedido, tipoPedido, nombreEmpresa, carrito, configuracion, codigoDescuento).then((resMail: any) => { });
    });
  }


  getDataPaypal(carrito, recargos) {
    let productos = [];
    carrito.forEach(prod => {
      let data = {
        name: prod.nombre,
        quantity: prod.cantidad,
        // category: prod.nombre,
        unit_amount: {
          currency_code: 'USD',
          value: prod.precio,
        }
      }
      productos.push(data);
    });

    if (recargos.costoEnvio > 0) {
      let envio = {
        name: "VALOR DE ENVIO",
        quantity: 1,
        unit_amount: {
          currency_code: 'USD',
          value: recargos.costoEnvio,
        }
      }
      productos.push(envio);
    }

    if (recargos.recargoTarjeta > 0) {
      let tarjeta = {
        name: "RECARGO TARJETA",
        quantity: 1,
        unit_amount: {
          currency_code: 'USD',
          value: recargos.recargoTarjeta,
        }
      }
      productos.push(tarjeta);
    }


    return productos;
  }


}
