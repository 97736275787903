import { Component, OnInit } from '@angular/core';
import { WebService } from '../../web.service';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {

  public informacion: any;

  public configuracion = {
    id: "",
    imgLogo: "",
    colorPrincipal: "",
    tituloPromocion: ""
  };

  constructor(
    private webService: WebService
  ) { }

  ngOnInit() {
    this.getDatos();
    this.getConfiguracion();
  }

  getDatos() {
    this.webService.getInformacion().then((data) => {
      this.informacion = data[0];
    });
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.configuracion.id = data[0].id;
      this.configuracion.imgLogo = data[0].imgLogo;
      this.configuracion.colorPrincipal = data[0].colorPrincipal;
      this.configuracion.tituloPromocion = data[0].tituloPromocion;

      let m = document.getElementById('main-navbar');
      m.style.background = data[0].colorPrincipal;

      let fotSect = document.getElementById('footer-section');
      fotSect.style.background = data[0].colorPrincipal;

    });
  }

}
