import { Component, OnInit } from '@angular/core';
import { WebService } from '../../web.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.scss']
})
export class PoliticasComponent implements OnInit {
  public configuracion: any;
  public mostrarInfo = {
    politicasPrivacidad: false,
    terminosCondiciones: false,
    politicasEnvioEntrega: false
  }
  constructor(
    private webService: WebService,
    private rutaActiva: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getConfiguracion();
    this.rutaActiva.params.subscribe((params: Params) => {
      let num = parseInt(params.id);
      this.mostrarInformacion(num);
    });
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.configuracion = data[0];
      // let m = document.getElementById('main-navbar');
      // m.style.background = data[0].colorPrincipal;
    });
  }

  // 1 = Politicas y privacidad
  // 2 = Terminos y condiciones
  // 3 = Politicas de envio y entrega
  mostrarInformacion(identificador) {
    switch (identificador) {
      case 1: {
        this.mostrarInfo.politicasPrivacidad = true;
        this.mostrarInfo.terminosCondiciones = false;
        this.mostrarInfo.politicasEnvioEntrega = false;
        break;
      }
      case 2: {
        this.mostrarInfo.politicasPrivacidad = false;
        this.mostrarInfo.terminosCondiciones = true;
        this.mostrarInfo.politicasEnvioEntrega = false;
        break;
      }
      case 3: {
        this.mostrarInfo.politicasPrivacidad = false;
        this.mostrarInfo.terminosCondiciones = false;
        this.mostrarInfo.politicasEnvioEntrega = true;
        break;
      }
    }
    // Ir hacia arriba
    if (screen.width >= 768 && screen.height >= 481) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 5);
    }
  }

}
