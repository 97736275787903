// Solucion a error de firebase ya en produccion
import * as firebase from 'firebase';
import 'firebase/firestore';
//Hasta aqui
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule } from '@angular/forms';

import { GapiSession } from '../infrastructure/sessions/gapi.session'
import { AppRepository } from 'src/infrastructure/repositories/app.repository';
import { FileRepository } from 'src/infrastructure/repositories/file.repository';
import { UserRepository } from 'src/infrastructure/repositories/user.repository';
import { AppContext } from 'src/infrastructure/app.context';
import { BreadCrumbSession } from 'src/infrastructure/sessions/breadcrumb.session';
import { FileSession } from 'src/infrastructure/sessions/file.session';
import { UserSession } from 'src/infrastructure/sessions/user.session';
import { AppSession } from 'src/infrastructure/sessions/app.session';

import { NgxLoadingModule } from 'ngx-loading';
import { MenuComponent } from './componentes/menu/menu.component';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { AdminComponent } from './admin/admin.component';
import { CarritoComponent } from './paginas/carrito/carrito.component';
import { CatalogoComponent } from './paginas/catalogo/catalogo.component';
import { ProductoComponent } from './componentes/producto/producto.component';
import { MenuadminComponent } from './componentes/menuadmin/menuadmin.component';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrupoComponent } from './componentes/grupo/grupo.component';

import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { SoporteComponent } from './paginas/soporte/soporte.component';
import { InformacionComponent } from './paginas/informacion/informacion.component';
import { NgxPrintModule } from 'ngx-print';
import { NgxPayPalModule } from 'ngx-paypal';


import { Ng2TelInputModule } from 'ng2-tel-input';
import { FooterComponent } from './componentes/footer/footer.component';
import { PoliticasComponent } from './paginas/politicas/politicas.component';


export function initGapi(gapiSession: GapiSession) {
  console.log("el init del app");
  return () => gapiSession.initClient();
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    InicioComponent,
    AdminComponent,
    CarritoComponent,
    CatalogoComponent,
    ProductoComponent,
    MenuadminComponent,
    GrupoComponent,
    SoporteComponent,
    InformacionComponent,
    FooterComponent,
    PoliticasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    NgxPrintModule,
    Ng2TelInputModule,
    NgxPayPalModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initGapi, deps: [GapiSession], multi: true },
    GapiSession,
    AppRepository,
    FileRepository,
    UserRepository,
    AppContext,
    BreadCrumbSession,
    FileSession,
    GapiSession,
    UserSession,
    AppRepository,
    FileRepository,
    UserRepository,
    AppSession,
    AngularFirestore
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
