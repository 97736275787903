// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBo_Fy27SnrmFCS-QTUaezCYUv8h1ZBOrY",
    authDomain: "equimec-48939.firebaseapp.com",
    databaseURL: "https://equimec-48939.firebaseio.com",
    projectId: "equimec-48939",
    storageBucket: "equimec-48939.appspot.com",
    messagingSenderId: "535125083350",
    appId: "1:535125083350:web:7fc979af3b028ddad7b577",
    measurementId: "G-60WE1GK71M"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
