import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { WebService, productoCarrito } from '../../web.service'

import { LocalStorage } from '@ngx-pwa/local-storage';
import { Meta } from '@angular/platform-browser';




@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {

  public producto: any;
  public productos: any;
  public cantidadProducto = 1;

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorage,
    private webService: WebService,
    private meta: Meta
  ) {
    this.meta.updateTag({name: 'og:image', content: 'La nueva imagen'});
    this.meta.updateTag({name: 'title', content: 'El nuevo titulo'});
  }

  ngOnInit() {
    this.route.paramMap.subscribe((data: any) => {
      this.webService.getProductoCodigo(data.params.id).then((data) => {
        this.producto = data[0];
      })
    })
  }

  agregarAlCarrito(p) {
    this.localStorage.setItem('testeo', { id: 1, dato: "datitos" });
    
    let prod: productoCarrito = {
      nombre: p.pro_nom,
      cantidad: this.cantidadProducto,
      id_prod: p.pro_cod,
      precio: p.pA,
      img_web: p.img_web,
      stockReal : p.stockReal 
    };

    //console.log(this.localStorage.getItem('testeo'));

    this.webService.agregarAlCarrito(prod, 1);
    //console.log(p);
  }


}
