import { Component, NgZone } from '@angular/core';

import { WebService } from './web.service'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppContext } from 'src/infrastructure/app.context';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public informacion = {};
  public imgsBanner = [];

  public limite: 20;
  
  constructor(
    private webService: WebService,
    private modalCtrl: NgbModal,
    // private appContext: AppContext,
    private zone: NgZone
  ) {
    //this.signIn();
    
  }

  signIn() {
    // this.appContext.Session.Gapi.signIn()
    //   .then(() => {
    //     if (this.appContext.Session.Gapi.isSignedIn) {
    //       console.log("Se supone que logea");
    //       // this.router.navigate(["/dashboard"]);
    //     }
    //   });
  }

  irAlCarrito(){
    console.log("a la vista del carrito");
  }


}
