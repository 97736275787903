import { Component, OnInit } from '@angular/core';
import { WebService } from '../../web.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.scss']
})
export class SoporteComponent implements OnInit {

  public carrito = [];
  public totalCarrito;

  public datosPersonales = {
    nombresCompletos: "",
    correo: "",
    telefono: "",
    mensaje: "",
  }

  public html = "";

  public configuracion : any;

  public loading = false;

  constructor(
    private webService: WebService,
    private toaster: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {

    this.carrito = this.webService.getCarrito();
    //console.log(this.carrito);
    this.getConfiguracion();
  }

  enviarMailSoporte() {
    if (this.datosPersonales.nombresCompletos && this.datosPersonales.telefono && this.datosPersonales.correo && this.datosPersonales.mensaje) {
      this.loading = true;
      this.html = "Hola, mi nombre es " + this.datosPersonales.nombresCompletos + " y " + this.datosPersonales.mensaje + ".\n Mi número de télefono es : " + this.datosPersonales.telefono;
      let dataEmail = {
        h: this.html,
        correo_api: this.configuracion.correo_api,
        password_correo_api: this.configuracion.password_correo_api,
        correo_personal_api: this.configuracion.correo_personal_api,	
        receptor: this.datosPersonales.correo
      }
      console.log(dataEmail);
      this.toaster.warning('Espere un monento ..', '', { timeOut: 3000, positionClass: 'toast-bottom-right' });
      setTimeout(() => {
        this.webService.enviarMailSoporte(dataEmail).then((data) => {
          console.log("respuesta email", data);
          this.loading = false;
          this.toaster.success('Soporte técnico enviado!', 'Correcto', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.router.navigateByUrl("/");
        });
      }, 5000);
    } else {
      this.toaster.error('Error, complete los datos', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-right' });
    }
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.configuracion = data[0];
      let m = document.getElementById('main-navbar');
      m.style.background = data[0].colorPrincipal;
    });
  }
}
