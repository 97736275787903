import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WebService } from 'src/app/web.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { Observable } from 'rxjs/Observable';

export interface productoCarrito {
  id_prod: number,
  nombre: string,
  precio: number,
  cantidad: number,
  img_web: string
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  // public carrito = [];
  public carrito: Observable<productoCarrito[]>;
  public numProdCarrito = 0;

  public loading = false;

  public usuario = {
    usuario: "",
    clave: ""
  }
  public estadoLogin = false;
  public nombreUsuario;

  public closeResult: string;

  public configuracion: {
    id: "",
    imgLogo: "",
    colorPrincipal: "",
    tituloPromocion: "",
    loginStorage: ""
  }

  public whatsAppUsuario;
  public menuBlog;

  constructor(
    private modalCtrl: NgbModal,
    private webService: WebService,
    private toaster: ToastrService,
    private localStorage: LocalStorage,
  ) {
  }

  ngOnInit() {
    this.getConfiguracion();
    this.getDatos();
    // Obtener el numero de articulos en el carrito 
    let carritoGeneral = this.webService.getCarrito();
    this.numProdCarrito = carritoGeneral.length;
    // Subcribirse cuando se agrega al carrito articulos
    this.carrito = this.webService.getNumeroProductosCarrito();
    this.carrito.subscribe(car => {
      this.numProdCarrito = car.length;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.configuracion = data;
      // console.log('Configuracion', this.configuracion);
      let l = this.localStorage.getItem(data[0].loginStorage).subscribe((login) => {
        if (login) {
          this.obtenerNombreApellido(login.nombres, login.apellidos);
          this.estadoLogin = true;
        }
        l.unsubscribe();
      });
      // console.log("Configuracion", this.configuracion[0]);
    });
  }

  loginCliente(loginUser) {
    this.usuario = {
      usuario: "",
      clave: ""
    }
    this.modalCtrl.open(loginUser, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (this.usuario.usuario && this.usuario.clave) {
        this.loading = true;
        this.webService.loginCliente(this.usuario.usuario, this.usuario.clave).then((datalogin) => {
          if (datalogin[0]) {
            // console.log("data del login", datalogin[0]);
            let s = this.localStorage.setItem(this.configuracion[0].loginStorage, datalogin[0]).subscribe((login) => {
              this.obtenerNombreApellido(datalogin[0].nombres, datalogin[0].apellidos);
              this.estadoLogin = true;
              this.toaster.success('Bienvenido ' + this.nombreUsuario, '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
              s.unsubscribe();
            });
          } else {
            this.toaster.warning('Usuario no encontrado', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
          }
          this.loading = false;
        });
      } else {
        this.toaster.error('Ingrese sus credenciales!', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cerraSesion() {
    console.log("cerrar sesion");
    this.localStorage.removeItem(this.configuracion[0].loginStorage).subscribe((cerrarSesion) => {
      this.toaster.success('Sesión cerrada con éxito', '', { timeOut: 3000, positionClass: 'toast-bottom-center' });
      this.nombreUsuario = "";
      this.estadoLogin = false;
    });
  }

  obtenerNombreApellido(nombres, apellidos) {
    let nom = nombres.split(" ");
    let apell = apellidos.split(" ");
    let n = nom[0].toLowerCase();
    let a = apell[0].toLowerCase();
    if(n && a){
      this.nombreUsuario = n[0].toUpperCase() + n.slice(1) + " " + a[0].toUpperCase() + n.slice(1);
    }else{
      if(n){
        this.nombreUsuario = n[0].toUpperCase() + n.slice(1);
      }
      if(a){
        this.nombreUsuario = a[0].toUpperCase() + n.slice(1);
      }
      
    }

  }

  contactanosWhatsApp(whatsApp) {
    this.whatsAppUsuario = "";
    this.modalCtrl.open(whatsApp, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', windowClass: 'modal-whatsapp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (this.whatsAppUsuario) {
        this.webService.getInformacion().then((dataInformacion: any) => {
          window.open("https://wa.me/" + dataInformacion[0].whatsapp + "?text=" + this.whatsAppUsuario + ".", "_blank");
        });
      } else {
        this.toaster.error('Ingrese algún texto', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-center' });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });


  }

  getDatos() {
    this.menuBlog = "";
    this.webService.getInformacion().then((data) => {
      // console.log("Informacion", data[0]);
      this.menuBlog = data[0].blog;
    });
  }

}
