import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WebService, productoCarrito } from 'src/app/web.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.scss']
})
export class GrupoComponent implements OnInit {

  public producto: any;
  public productos: any;
  public productosMostrar: any;
  public cantidadProducto = 1;
  public tituloProd = "";

  idProducto: string;
  // productoJ: any;
  // url = 'http://www.rosstorequevedo.com/grupo/';
  urlpro;
  urlCompartir;

  public configuracion = {
    id: "",
    imgLogo: "",
    colorPrincipal: "",
    tituloPromocion: "",
    tipoPrecio: "",
    loginStorage: "",
    dominioPagina: "",
  };

  public precioMostrar;

  public stockDisponible = [];


  // public imgProductos = [
  //   { url: '1GrcMk0_HLjjJkjApbNwIVgSXAmX7PyQF' },
  //   { url: '1Je0l6z5ZFHv1tEzEXzq2MfJeRhffENIy' },
  //   { url: '1SSRnw4fVgmzo_7bIVaubkKK2zfExo0uE' },
  //   { url: '1zTF5yR1ElH2IJ3GU2h0-htEAwVlaPijE' }
  // ]

  // public imgPrincipal = '1GrcMk0_HLjjJkjApbNwIVgSXAmX7PyQF';
  public imgProductos = [];
  public imgPrincipal = '';

  constructor(
    // private route: ActivatedRoute,
    private localStorage: LocalStorage,
    private webService: WebService,
    public meta: Meta,
    private rutaActiva: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router
  ) {
    this.getConfiguracion();
    // console.log(this.imgProductos);
    // console.log(this.imgPrincipal);
  }

  ngOnInit() {
    this.rutaActiva.params.subscribe((params: Params) => {
      // console.log("asdas", params.id);
      this.idProducto = params.id;
      this.getDataProducto(params.id);
    });
  }

  getDataProducto(pro_cod) {
    this.imgProductos = []
    this.stockDisponible = [];
    this.webService.getProductoCodigo(pro_cod).then((result: any) => {
      // console.log(result); 
      result.stockReal = parseFloat(parseFloat(result.stockactual).toFixed(2));
      // Crear arreglo con el stock disponilble
      this.stockDisponible = this.webService.crearArregloStock(parseFloat(parseFloat(result.stockactual).toFixed(2)));
      this.imgProductos = [
        { 'id': 1, 'imagen': result.imagen_uno },
        { 'id': 2, 'imagen': result.imagen_dos },
        { 'id': 3, 'imagen': result.imagen_tres },
        { 'id': 4, 'imagen': result.imagen_cuatro },
      ];
      this.imgPrincipal = this.webService.obtenerImagenPrincipalProducto(this.imgProductos);
      result.imagenes = this.imgProductos;
      result.img_web = this.webService.obtenerImagenPrincipalProducto(this.imgProductos);
      this.productosMostrar = result;
      // console.log("imagen principal", this.imgPrincipal);
      
      let l = this.localStorage.getItem(this.configuracion.loginStorage).subscribe((login) => {
        if (login) {
          // console.log("entra al if", login);
          this.configuracion.tipoPrecio = login.default_price;
          this.definirTipoPrecio(result);
        } else {
          // console.log("entra al else no login");
          this.webService.getConfiguracion().then((dataConfiguracion: any) => {
            // console.log(dataConfiguracion);
            this.configuracion.tipoPrecio = dataConfiguracion[0].tipoPrecio;
            this.definirTipoPrecio(result);
          });
        }
        // console.log(this.configuracion.tipoPrecio + ": " + this.precioMostrar);
        this.producto = result;
        this.meta.updateTag({ property: "og:url", content: this.configuracion.dominioPagina + "/grupo/" });
        this.meta.updateTag({ property: "og:image", content: 'https://drive.google.com/uc?export=view&id=' + this.producto.img_web });
        // console.log("Producto", this.productosMostrar);
        l.unsubscribe();
      });
    });
  }

  //Eliminar duplicados
  eliminarDuplicadosTalla(originalArray, talla) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][talla]] = originalArray[i];
      // console.log("",originalArray[i][talla]);
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  definirTipoPrecio(data) {
    // console.log("Producto para el precio", data);
    // console.log("tipo precio", this.configuracion);
    for (var p in data) {
      if (p == this.configuracion.tipoPrecio) {
        if (data[p]) {
          this.precioMostrar = data[p];
          console.log("tipo precio if", this.precioMostrar, p);
        } else {
          this.precioMostrar = data.pA;
          console.log("tipo precio else", this.precioMostrar, p);
        }
      }
    }
  }

  agregarAlCarrito(p) {
    let prod: productoCarrito = {
      nombre: p.pro_nom,
      cantidad: this.cantidadProducto,
      id_prod: p.pro_cod,
      precio: this.precioMostrar,
      img_web: p.img_web,
      stockReal: p.stockReal
    };
    this.webService.agregarAlCarrito(prod, 1);
    this.router.navigate(['carrito']);
  }

  cambiarProducto(p) {
    let obj = this.productos.find(function (e) {
      return e.pro_cod == p;
    })
    //console.log(obj);
    this.producto = obj;
  }

  compartir() {
    // Este se encuentra funcionando actualmente
    // let url = "http://www.facebook.com/sharer.php?u=" + encodeURIComponent("https://drive.google.com/uc?export=view&id=" + this.productoJ.pro_cod);
    // window.open(url, 'sharer', '_blank');

    let url = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.configuracion.dominioPagina + "/grupo/" + this.productosMostrar.pro_cod);
    console.log(url);
    window.open(url, 'sharer', '_blank');
    // https://www.facebook.com/sharer/sharer.php?u=

    //"&link=" + encodeURIComponent(this.producto.url)+ 
    //window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent("https://drive.google.com/uc?export=view&id="+this.producto.img_web)+'&description='+encodeURIComponent("El titulo") ,'sharer','toolbar=0,status=0,width=626,height=436');return false;
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.configuracion.id = data[0].id;
      this.configuracion.imgLogo = data[0].imgLogo;
      this.configuracion.colorPrincipal = data[0].colorPrincipal;
      this.configuracion.tituloPromocion = data[0].tituloPromocion;
      this.configuracion.loginStorage = data[0].loginStorage;
      this.configuracion.dominioPagina = data[0].dominioPagina;
      // console.log("configuracion Grupo", this.configuracion);
      // let m = document.getElementById('main-navbar');
      // m.style.background = this.configuracion.colorPrincipal;
    });
  }

  generarLinkCompartirFB() {
    // console.log(producto);
    this.urlpro = this.configuracion.dominioPagina + "/grupo/" + this.productosMostrar.pro_cod;
    this.urlCompartir = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.urlpro);
    // console.log(this.urlCompartir);
    window.open(this.urlCompartir, 'sharer', '_blank');
  }

  obtenerCantidadProducto(s) {
    console.log(s.target.value);
    this.cantidadProducto = s.target.value;
  }

  seleccionarImagenPrincipal(url) {
    // console.log(url);
    this.imgPrincipal = url;
  }

  aplicarZoom() {
    let imgP = document.getElementById('img-principal');
    imgP.style.transform = 'scale(1.8, 1.8)';
  }

  quitarZoom() {
    let imgP = document.getElementById('img-principal');
    imgP.style.transform = 'scale(1)';
  }

  cambiarImagenPrincipal(img, cambiar){
    let tam = 0;
    this.imgProductos.forEach(i =>{
      if(i.imagen){
        tam ++;
      }
    });
    this.imgProductos.forEach(i =>{
      if(img == i.imagen){
        if(cambiar == 'sig'){
          if(i.id == tam){
            this.imgPrincipal = this.imgProductos[0].imagen;
          }else{
            this.imgPrincipal = this.imgProductos[i.id].imagen;
          }          
        }
        if(cambiar == 'ant'){
          if(i.id == 1){
            this.imgPrincipal = this.imgProductos[tam -1].imagen;
          }else{
            let num = i.id - 2;
            this.imgPrincipal = this.imgProductos[num].imagen;
          }   
        }
        
      }
    });
  }

}
