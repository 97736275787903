import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/web.service';
import { Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

let ejecutar;


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  public informacion: any;

  public loading = true;

  public configuracion = {
    id: "",
    imgLogo: "",
    colorPrincipal: "",
    tituloPromocion: "",
    dominioPagina: ""
  };

  public promociones: {
    id: "",
    nombrePromocion: "",
    nombreImagen: "",
    imgPromocion: "",
  }

  public urlVideo;
  public mostrarVideo = false; ç

  // Desde aqui nueva interfaz

  public imgsBanner = [
    {
      span: "Bienvenido a PuntoPymes",
      h1: "Pequeños detalles hacen la",
      h1Strong: "Diferencia",
      p: "Nos enfocamos en los pequeños detalles para ajustarnos a sus necesidades y brindarle la mejor experiencia.",
      url: '../../../assets/img/interface/bg_1.jpg',
    },
    {
      span: "Bienvenido a PuntoPymes",
      h1: "Diseño estratégico y",
      h1Strong: "Tecnología",
      p: "Apoyados en las mejores tecnologías brindamos las mejores soluciones de software para su negocio.",
      url: '../../../assets/img/interface/bg_22.png',
    },
  ];

  servicios = {
    mision: true,
    vision: false,
    equipo: false,
    productos: false
  }

  serviciosOfrecer = [
    {
      titulo: "Sistema ERP ",
      descripcion: "Creamos soluciones de software a medida de las industrias y comercios con innovadores sistemas puntos de ventas, rápidos y agiles que permiten un control total de los negocios, sistemas contables y financieros, con automatización de procesos y administración de los recursos de la empresa  "
    },
    {
      titulo: "Sistemas CRM",
      descripcion: "Nuestros sistemas ERP organizan la información de tu cliente con verdaderos reportes para no perder ninguna venta, incorpora agenda virtual de seguimiento de las tareas asignadas. Gestion del proceso de atención y un Mapa de procesos de satisfacción del cliente. "
    }, {
      titulo: "Aplicaciones Móviles ",
      descripcion: "Nuestras aplicaciones móviles están orientadas a la gestión del negocio brindando servicios complementarios en el área de ventas, gestión de pedidos, seguimiento de fuerza de ventas, geolocalización de clientes y vendedores, además de un sistema de rastreo satelital y gestión de Logistica de despacho de envíos "
    }, {
      titulo: "E- comerce",
      descripcion: "Nuestras tiendas en línea son una verdadera herramienta de ventas en internet además que se integran a nuestro ERP fácilmente y es posible que en menos de 24 horas ya tengas tu negocio en internet con los servicios de las grandes E-comerce mundiales como : catálogos de productos , inventarios reales en línea, toma de pedidos, botones de pago con pasarelas de pago de tarjetas de crédito, carrito de compras, e integración de redes sociales, chat activo con API WhatsApp y Facebook-"
    }
  ]

  public nuestroEquipo = [
    {
      imagen: "../../../assets/img/interface//nuestroEquipo/cristian1.jpg",
      descripcion: "Ventas"
    },
    {
      imagen: "../../../assets/img/interface/nuestroEquipo/karina_021.jpg",
      descripcion: "Call center"
    },
    {
      imagen: "../../../assets/img/interface/nuestroEquipo/progra_011.jpg",
      descripcion: "Equipo de desarrollo"
    },
    {
      imagen: "../../../assets/img/interface/nuestroEquipo/sala-de-juntas1.jpg",
      descripcion: "Sala de juntas"
    }
  ]

  public oficinas = [
    {
      oficina: "Principal",
      direccion: "Loja, Azuay 12606 y Bernardo Valdivieso PBX 593-07-726897, Ing. Edison Macas Estrada"
    },
    {
      oficina: "Oficina Quito Norte",
      direccion: "Av. la prensa y Rio Blanco en Edificio de Copyone, Ing. Kevin Benitez"
    },
    {
      oficina: "Oficina Quito Sur",
      direccion: "Galte S9-78 y Rodrigo de Chávez, Ing. Daniel Guerrón Benalcázar MGS, Cel. 0984930731"
    },
    {
      oficina: "Oficina Cuenca",
      direccion: "Calle larga y Manuel Vega, Interior Restaurante Maiz, Ing. Fernando Salazar"
    },
    {
      oficina: "Oficina Santo Domingo",
      direccion: "José María Egas y Av. Abraham Calazacón, Ing. Javier Calderon"
    },
    {
      oficina: "Oficina Guayaquil Sur",
      direccion: "Coop Florida, Union de Bananeros solar 6 y av juan perdala, Ing. Julio Jara"
    },
    {
      oficina: "Oficina Guayaquil Norte",
      direccion: "Cdla. La alborada, Calle Benjamin Carrion 13 solar 10 y 11 Ing. Luisa Ramírez"
    },
    {
      oficina: "Oficina Loja - Saraguro",
      direccion: "Casa FIIS Calle el Oro y Av Loja, Telf:0985864656 Ing. Darwin Ortega"
    },
  ]

  contactos = [
    {
      tipo: "Dirección",
      descripcion: "Azuay entre Bernardo Valdivieso y Olmedo, Loja - Ecuador",
      href: "",
      icono: "fas fa-map-signs"
    },
    {
      tipo: "Teléfono",
      descripcion: "593- 72726898",
      href: "tel://(593- 72726898",
      icono: "fas fa-phone-alt"
    },
    {
      tipo: "Correo electronico",
      descripcion: "puntopymes@sofpymes.com",
      href: "mailto:sofpymes@gmail.com",
      icono: "fab fa-telegram-plane"
    },
    {
      tipo: "Página Web",
      descripcion: "https://www.sofpymes.com/",
      href: "#",
      icono: "fas fa-globe-americas"
    },
  ]

  public pasarelasPago = {
    placetoPay: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
    dataFast: {
      nombre: '',
      token: '',
      secret_key: '',
      url: ''
    },
  }


  constructor(
    private webService: WebService,
    public meta: Meta,
    private _sanitizer: DomSanitizer
  ) {
    // this.webService.getImgBanner().then((data: []) => {
    //   this.imgsBanner = data;
    //   // console.log(this.imgsBanner);
    // });
  }

  ngOnInit() {
    this.getConfiguracion();
    // this.getDatos();
    // this.getPromociones();
    this.getPasarelasPago();
  }

  getDatos() {
    this.webService.getInformacion().then((data) => {
      this.informacion = data[0];
      if (this.informacion.video) {
        this.mostrarVideo = true;
        this.pocesarUrlVideo(this.informacion.video);
      }
    })
  }

  getConfiguracion() {
    this.loading = true;
    this.webService.getConfiguracion().then((data: any) => {
      this.loading = false;
      this.configuracion = data[0];
      this.meta.updateTag({ property: "og:url", content: this.configuracion.dominioPagina });
      this.meta.updateTag({ property: "og:image", content: "https://drive.google.com/open?id=15Rqx2hoxpiQoR7bN5eioTO2WIjJaFv8r" });
    });
  }

  getPromociones() {
    this.loading = true;
    this.webService.getPromociones().then((data: any) => {
      this.promociones = data;
      this.loading = false;
    })
  }

  pocesarUrlVideo(url) {
    let s = '=';
    let a = url.split(s, 2);
    this.urlVideo = this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + a[1]);
  }

  // Desde aqui na nueva interfaz

  public menuServicios(value) {
    switch (value) {
      case 'mision':
        this.servicios.mision = true;
        this.servicios.vision = false;
        this.servicios.equipo = false;
        this.servicios.productos = false;
        break;
      case 'vision':
        this.servicios.mision = false;
        this.servicios.vision = true;
        this.servicios.equipo = false;
        this.servicios.productos = false;
        break;
      case 'equipo':
        this.servicios.mision = false;
        this.servicios.vision = false;
        this.servicios.equipo = true;
        this.servicios.productos = false;
        break;
      case 'productos':
        this.servicios.mision = false;
        this.servicios.vision = false;
        this.servicios.equipo = false;
        this.servicios.productos = true;
        break;
    }

  }

  getPasarelasPago() {
    this.webService.obtenerBotonesPago().then(async (res: any) => {
      if (res.length > 0) {
        for (let b of res) {
          if (b.nombre == "DataFast") {
            this.pasarelasPago.dataFast.nombre = b.nombre;
            this.pasarelasPago.dataFast.token = b.token;
            this.pasarelasPago.dataFast.secret_key = b.secret_key;
            this.pasarelasPago.dataFast.url = b.url;
            await this.obtenerTransacionespendientesBtnPago(this.pasarelasPago.dataFast);
          }
          if (b.nombre == "PlacetoPay") {
            this.pasarelasPago.placetoPay.nombre = b.nombre;
            this.pasarelasPago.placetoPay.token = b.token;
            this.pasarelasPago.placetoPay.secret_key = b.secret_key;
            this.pasarelasPago.placetoPay.url = b.url;
            await this.obtenerTransacionespendientesBtnPago(this.pasarelasPago.placetoPay);
          }
        }

      }

    });
  }

  // Obtner transacciones pendientes de las pasarela de pagos
  async obtenerTransacionespendientesBtnPago(pasarelasPago) {
    await this.webService.getPedidosBtnPagosEmpresa(pasarelasPago.nombre).then(async (res: any) => {
      for (let p of res) {
        if (p.estado_transaccion == 'PENDING') {
          await this.webService.estadoTransaccionDatafastIdPedido(pasarelasPago, p.id_pedido).then(async (res) => {
            await this.actualizarTransaccionesPendientesDataFast(res, p, this.configuracion);
          });
        }
      }
    });
  }

  async actualizarTransaccionesPendientesDataFast(data, pedido, configuracion) {
    let transaccion = data.res;
    if (transaccion.result) {
      if (transaccion.result.code = '000.000.100') {
        let codigoEstado = transaccion.payments[0].result.code;
        let tipoPedido = 6;
        if (codigoEstado == '000.100.112' || codigoEstado == '000.000.000') {
          pedido.estado_transaccion = 'APPROVED';
          pedido.id_transaccion = transaccion.payments[0].id;
          await this.webService.updatePedido(pedido.id_pedido, pedido).then((resActualizar) => { });
          await this.webService.transaccionAprobadaEnviarCorreo(pedido.id_pedido, pedido, tipoPedido, configuracion);
          // await this.webService.vaciarCarrito();
        } else {
          await this.webService.deletePedidoDetalle(pedido.id_pedido).then((res) => { });
          await this.webService.deletePedido(pedido.id_pedido).then((res) => { });
        }
      }
    } else {
      if (transaccion.error) {
        console.log("No existe la transacion en la pasarela");
        await this.webService.deletePedidoDetalle(pedido.id_pedido).then((res) => { });
        await this.webService.deletePedido(pedido.id_pedido).then((res) => { });
      }
    }
  }
}
