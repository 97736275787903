import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebService } from '../../web.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public mostraFooter;
  constructor(
    private router: Router,
    private webService: WebService
  ) { }

  ngOnInit() {
    this.getConfiguracion();
  }

  getConfiguracion() {
    this.webService.getConfiguracion().then((data: any) => {
      this.mostraFooter = data[0].terminosCondiciones;
      // console.log("mostraFooter", this.mostraFooter);
    });
  }

  irPoliticas(identificador){
    this.router.navigateByUrl("politicas/" + identificador);
  }
  
}
